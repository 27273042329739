import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from 'react-select';

import { Grid, styled, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useFormControls from './Validations';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { editUser, getUserRoles } from 'src/redux/actions/userActions';
import { Dispatch } from 'redux';
import { getAllPermissions } from 'src/redux/actions/authActions';

const AddButton = styled(Button)(
  () => `
        color: white !important;
        background: #15364A  !important;
        border-radius: 3px  !important;
        height: 38px !important;
        width: 63px !important

        :hover {
            background-color:  #022f53  !important;
          }
        :disabled {
          color: #aeaeae !important;
        }
        `
);
const CancelButton = styled(Button)(
  () => `
  background: #FFFFFF  !important;
  border: 1px solid #15364A  !important;
  border-radius: 3px  !important;
  color: #15364A  !important;
  height: 38px !important;
  width: 84px !important
      
          :hover {
              background-color: white  !important;
            }
          
          `
);
const CustomInput = styled(TextField)(
  () => `
  .MuiInputBase-root {
    height: 36px;
    background: #F4F7F9 ;
    border-radius: 4px;
    width: 100%;
  }

        :after{
          border-bottom: unset !important;

        }
        :before{
          border-bottom: unset !important;

        }

        .Mui-disabled:after {
          border-bottom: unset !important;
          border-bottom-style: solid;
        }
        .Mui-disabled:before {
          border-bottom: unset;
          border-bottom-style: solid;
        }
        .Mui-disabled {
          color: white !important;
          background: #f5f5f5;
        }
      `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);

export default function RoleForm({ isEdit, editRoleData, close }) {
  const dispatch = useDispatch();

  const rolesData = useSelector((state) => state?.users?.roles);
  const permissionsData = useSelector((state) => state?.auth?.allPermissions);
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const [selectedPermissions, setSelectedPermissions] = useState(
    editRoleData?.permissions?.map((d) => ({
      label: d?.permissionName,
      value: d
    })) || []
  );

  const [editRole, setEditRole] = useState({});

  const rolesOption = permissionsData?.map((permissionsData) => {
    return {
      label: permissionsData?.permissionName,
      value: permissionsData
    };
  });

  const handlePermissionsChange = (selectedOptions) => {
    setSelectedPermissions(selectedOptions);
  };

  useEffect(() => {
    dispatch(getAllPermissions(authToken));
  }, []);

  const filteredOptions = permissionsData
    ? permissionsData.filter(
        (permission) =>
          !selectedPermissions.some(
            (selected) =>
              selected.value.permissionName === permission.permissionName
          )
      )
    : [];

  const options = filteredOptions.map((d) => ({
    label: d.permissionName,
    value: d
  }));

  useEffect(() => {
    setEditRole(editRoleData);
    setSelectedPermissions(
      editRoleData?.permissions?.map((d) => ({
        label: d?.permissionName,
        value: d
      })) || []
    );
  }, [editRoleData]);


  useEffect(() => {
    resetFields();
  }, [close]);

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
    resetFields,
    values
  } = useFormControls(editRoleData, selectedPermissions);

  const handleSaveClick = (e) => {
    close();
    handleFormSubmit(e, resetFields(), isEdit);
  };

  const handleCancelClick = () => {
    close();
    resetFields();
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {!isEdit ? (
            <>
              <Grid item xs={12} sx={{ margin: '10px 0' }}>
                <LabelBox>
                  Role Name<span style={{ color: 'red' }}> *</span>
                </LabelBox>
                <CustomInput
                  name="description"
                  onBlur={(e) => handleInputValue(e, 'string', '')}
                  onChange={(e) => handleInputValue(e, 'string', '')}
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  value={values?.description}
                  autoComplete="none"
                  {...(errors['description'] && {
                    error: true,
                    helperText: errors['description']
                  })}
                  type="text"
                />
              </Grid>
              <Grid display={"flex"} item xs={12} sx={{ margin: '10px 0' }}>
                <LabelBox>
                  Permissions<span style={{ color: 'red' }}> *</span>
                </LabelBox>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  {...(errors['permissions'] && {
                    error: true,
                    helperText: errors['permissions']
                  })}
                  name="roles"
                  value={values?.roles?.map((d) => {
                    return { label: d.label, value: d.value };
                  })}
                  display="flex !important"
                  options={rolesOption}
                  className="basic-multi-select input_box"
                  classNamePrefix="select"
                  onChange={(e) => handleInputValue(e, 'array', 'permissions')}
                />
              </Grid>{' '}
            </>
          ) : (
            <>
              {' '}
              <Grid item xs={12} sx={{ margin: '10px 0' }}>
                <LabelBox>
                  Role Name<span style={{ color: 'red' }}> *</span>
                </LabelBox>
                <CustomInput
                  name="description"
                  onBlur={(e) => handleInputValue(e, 'string', '')}
                  onChange={(e) => handleInputValue(e, 'string', '')}
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  value={values?.description}
                  autoComplete="none"
                  {...(errors['description'] && {
                    error: true,
                    helperText: errors['description']
                  })}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sx={{ margin: '10px 0' }}>
                <LabelBox>
                  Permissions<span style={{ color: 'red' }}> *</span>
                </LabelBox>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  name="permissions"
                  value={selectedPermissions}
                  options={options}
                  className="basic-multi-select input_box"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    handlePermissionsChange(selectedOptions)
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box
          sx={{
            '& > :not(style)': { ml: 2, mt: 3 },
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <Grid item xs={6} md={6}>
            <AddButton
              onClick={(e) => handleSaveClick(e)}
              disabled={!formIsValid()}
              type="submit"
            >
              Add
            </AddButton>
          </Grid>
          <Grid item xs={6} md={6}>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          </Grid>
        </Box>
      </form>
    </>
  );
}
