import {
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import $ from 'jquery';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useNavigate } from 'react-router-dom';
import DeleteIcon from 'src/assets/Icons/Delete.png';
import CustomModal from '@mui/material/Modal';

import moment from 'moment';
import {
  Box,
  Button,
  styled,
  TextField,
  Grid
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import PlaceOrderForm from './PlaceOrderForm';
import {
  updateLeadTime
} from 'src/redux/actions/CustomerPortalActions';
import { downloadLogo, getCustomer } from 'src/redux/actions/sysAdminActions';
import {
  getAllCartOrderByUserId,
  getAllCustomerShippingLocations
} from 'src/redux/actions/customerCartActions';
import { ActionTypes } from 'src/redux/constants/action-types';
import { toast } from 'react-toastify';
import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';

const CustomInput = styled(TextField)(
  () => `
  .MuiInputBase-root {
    height: 40px;
    border-radius: 0px;
    width: 100%;
    background: #F4F7F9;

    border: 1px solid #B8C2C8;
    color: #15364A;
  }

        :after{
          border-bottom: unset !important;
          border: 1px solid #20A2F3 !important;

        }
        :before{
          border-bottom: unset !important;
          border: 1px solid #20A2F3 !important;

        }

        .Mui-disabled:after {
          border-bottom: unset !important;
          border-bottom-style: solid;
          background: white;
        }
        .Mui-disabled:before {
          border-bottom: unset;
          border-bottom-style: solid;
          background: white;
        }
        .Mui-disabled {
          background: white;
        }
        .css-trgup9-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
          // border-color: unset;
        }
      `
);

const HeaderBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);

const LabelBox = styled(Box)(
  () => `
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
      `
);

const TabItem = styled(Box)(
  () => `

  font-family: Open Sans;
  
  padding: 22px;
  cursor: pointer;
  color: #72869A;
  
font-weight: 700;
font-size: 18px;
  
height: 61px;
margin-right: 12px;

  
  `
);

const PartNameText = styled(Box)(
  () => `

  font-weight: 700;
  font-size: 14px;
  `
);

const InputBox = styled(Box)(
  () => `
  background: #15364A;
  border: 1px solid #15364A;
  color: white;
  width: 52px;

  
  `
);

const ModalButton = styled(Button)(
  () => `
  background-color: #15364a !important;
    color: white !important;
    height: 40px !important;
    border-radius: 3px !important;
    padding: 0 13px !important;
    font-weight: 600;
    font-size: 16px;

    :hover {
        background-color: black !important;
      }
    `
);

const ModalHeader = styled(Box)(
  () => `
    font-weight: 700;
     font-size: 20px;
    `
);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '40%', sm: '100%' },
  maxHeight: { md: '500px', sm: '100%' },
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 3,
  bordeRadius: '5px'
};

const placeOrderStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '60%', sm: '100%' },
  maxHeight: { md: '500px', sm: '100%' },
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  p: 3,
  bordeRadius: '5px'
};

const CancelButton = styled(Button)(
  () => `
  color: white !important;
  background: #15364A  !important;
  border-radius: 3px  !important;
  height: 38px !important;
  width: 63px !important

  :hover {
      background-color:  #022f53  !important;
    }
    :disabled {
      opacity: 0.8 !important;
      color: white
    }
  `
);

const TableContainer = styled(Box)(
  () => `
      padding: 15px 24px;
      background-color: white;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
      border-radius: 3px;
      `
);

const CustomerPortalTable = ({ customerID, customerNumber }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState('product list');
  const [openModal, setOpenModal] = useState(false);
  const [openPlaceOrderModal, setopenPlaceOrderModal] = useState(false);
  const [values, setValues] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [placeOrderData, setPlaceOrderData] = useState({});

  const [productListDataTableColumns, setProductListDataTableColumns] = useState(false);
  const [productListDataTableOptions, setProductListDataTableOptions] = useState(false);
  const [productListDataTableData, setProductListDataTableData] = useState(false);

  const [openOrdersDataTableColumns, setOpenOrdersDataTableColumns] = useState(false);
  const [openOrdersDataTableOptions, setOpenOrdersDataTableOptions] = useState(false);
  const [openOrdersDataTableData, setOpenOrdersDataTableData] = useState(false);

  const [incomingInventoryDataTableColumns, setIncomingInventoryDataTableColumns] = useState(false);
  const [incomingInventoryDataTableOptions, setIncomingInventoryDataTableOptions] = useState(false);
  const [incomingInventoryDataTableData, setIncomingInventoryDataTableData] = useState(false);


  const logoImage = useSelector((state) => state?.customers?.getLogo);
  const shippingLocations = useSelector(
    (state) => state?.customerPortal?.getCustomerShippingDetails
  );
  const authToken = useSelector((state) => state?.auth?.token?.token);
  const authUserRole = useSelector((state) => state?.auth?.authUserData);
  const customerData = useSelector((state) => state?.customers?.customerData);

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', margin: '0 0 12px 0' }),
    []
  );

  useEffect(async () => {
    if (customerData && customerData?.logo) {
      const fd = new FormData();
      fd.append('file', customerData?.logo);

      dispatch(
        downloadLogo(
          customerData?.customerNumber,
          customerData?.id,
          customerData?.logo,
          authToken,
          fd
        )
      );
    }
  }, [customerData?.id]);

  const deleteRecord = useCallback(
    (event, data) => {
      let dt = $('#ProductListTable').DataTable();
      // Deselect and uncheck
      dt.row((idx, d) => d.id === data.id).deselect();
      $('tr:not(".selected")', $('#ProductListTable tbody')).find('input').prop("checked", false).trigger("change");
      // Get selected rows data
      let dtselected = dt.rows({ selected: true }).data().toArray();
      if (dtselected.length === 0) {
        setSelectedData({});
        //close both modals (since only one is open at a time anyway)
        handleModalClose();
        handlePlaceOrderModalClose();
        return;
      }
      
      const shippingLoc = shippingLocations?.find((d) => d?.primary);
      dtselected = dtselected.map(item => {
        return {
          ...item,
          shippingLocationId: shippingLoc?.id,
          uom: customerData?.preferredUOM,
        };
      });

      setPlaceOrderData(dtselected);
      setSelectedData({ dtselected });
    },
    [selectedData]
  );

  const handleOpenLeadTime = (selectedRows) => {
    if (Object.values(selectedRows).flat().length) {
      setOpenModal(true);
      setPlaceOrderData(Object.values(selectedRows).flat());
    } else {
      toast.error('Please choose the part number to edit');
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    $('#ProductListTable').DataTable().rows().deselect();
    $('tr:not(".selected")', $('#ProductListTable tbody')).find('input').prop("checked", false).trigger("change");
  };

  const handleOpenPlaceOrder = (selectedRows) => {
    let sr = Object.values(selectedRows).flat();
    if (sr.length) {
      const shippingLoc = shippingLocations?.find((d) => d?.primary);
      const values = sr.map(item => {
        return {
          ...item,
          shippingLocationId: shippingLoc?.id,
          uom: customerData?.preferredUOM,
        };
      });
      setPlaceOrderData(values);

      setopenPlaceOrderModal(true);
    } else {
      toast.error('Please choose the part number to place order');
    }
  };

  const handlePlaceOrderModalClose = () => {
    dispatch({
      type: ActionTypes.GET_ADD_CART_RESPONSE,
      payload: []
    });

    setopenPlaceOrderModal(false);
  };


  useEffect(() => {
    if (customerData) {
      dispatch(getAllCustomerShippingLocations(customerData?.id, authToken));
    }
  }, [customerData]);

  //Set Table Callback
  useEffect(() => {
    //Adjusting the columns for tables when the tab gets selected
    if (tab === 'product list') {
      $('#ProductListTable').DataTable().columns.adjust();
    }
    if (tab === 'open orders') {
      $('#OpenOrdersTable').DataTable().columns.adjust();
    }
    if (tab === 'inventory') {
      $('#IncomingInventoryTable').DataTable().columns.adjust();
    }
  }, [tab])

  const handleLeadTimeChange = (e) => {
    let obj = values;

    obj = {
      ...obj,

      [e?.target.name]: e?.target.value
    };

    setValues(e?.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const leadsTimeData = Object.values(selectedData)
      .flat()
      ?.map((d) => {
        return {
          id: d?.id,
          partNumber: d?.partNumber,
          leadTime: parseInt(values)
        };
      });
    dispatch(
      updateLeadTime(
        customerData?.customerNumber,
        leadsTimeData,
        authToken,
        setValues,
        handleModalClose,
      )
    );

    setSelectedData({});
  };

  const handleDeselect = useCallback(() => {
    $('#ProductListTable').DataTable().rows().deselect();
    $('tr:not(".selected")', $('#ProductListTable tbody')).find('input').prop("checked", false).trigger("change");
  }, []);

  const editLeadsModal = useCallback(() => {
    return (
      <>
        {selectedData &&
          Object.values(selectedData).map((val, i) =>
            val?.map((d, j) => (
              <Box
                key={d}
                sx={{
                  '& > :not(style)': { mr: 2, mt: 3 },
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Grid sx={{ flexGrow: 1 }}>
                  <PartNameText>Part Number: {d?.partNumber}</PartNameText>
                </Grid>
                <Grid>
                  <img
                    className="Img"
                    width="20px"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => deleteRecord(e, d)}
                    src={DeleteIcon}
                  ></img>
                </Grid>
              </Box>
            ))
          )}
      </>
    );
  }, [selectedData]);

  //Product List Data Table settings
  useEffect(() => {
    let columns = [
      {
        name: 'checkbox',
        data: '',
        orderable: false,
        render: function (data, type, row) {
          return `<input type="checkbox" class="form-check-input" name="item${row.id}" id="item${row.id}" value="${row.id}">`;
        },
      },
      {
        title: 'Part Number',
        name: 'partNumber',
        data: 'partNumber',
      },
      {
        title: 'Description',
        name: 'description',
        data: 'description',
        render: function (data, type, row) {
          let linkUri = `/customer-portal/${customerID ?? customerData?.id}/product/${row.partNumber}?cp=true`;
          return `<a href="#" class="part-link" data-link="${linkUri}" data-partnumber="${row.partNumber}" data-desc="${row.description}" data-id="${row.id}">${data}</a>`;
        },
      },
    ];

    //Inventory Columns
    customerData?.branches?.map((d) => {
      columns.push({
        title: d.branchName,
        name: d.branchName,
        data: 'id',
        defaultContent: '',
        render: function (data, type, row, meta) {
          let returnval = 0;
          if (row.branchInventories.length > 0) {
            row.branchInventories.map(obj => {
              if (obj.branchName === meta.settings.aoColumns[meta.col].title) {
                returnval += parseInt(obj.quantity);
              }
            });
          }
          //Add uom after inventory for display
          returnval = Number(returnval).toLocaleString() + " " + row.uom;

          return returnval;
        },
      });
    });

    if(customerData?.branches?.length > 1){
      columns.push({
        title: 'Total Stock Quantity',
        name: 'totalStockQuantity',
        data: 'totalStockQuantity',
        render: function (data, type, row, meta) {
          return Number(row.totalStockQuantity).toLocaleString() + ' ' + row.uom;
        }
      });
    }
    columns.push({
        title: 'Inbound Quantity',
        name: 'inboundQuantity',
        data: 'inboundQuantity',
        render: function (data, type, row, meta) {
          return Number(row.inboundQuantity).toLocaleString() + ' ' + row.uom;
        }
      });

    // Forecast Columns
    if (customerData?.features?.some((d) => d?.featureCode === 'FC')) {
      columns.push({
        title: 'Lead Time Weeks',
        name: 'leadTimeWeeks',
        data: 'leadTimeWeeks',
        defaultContent: 0,
      },
        {
          title: 'Reorder Date',
          name: 'reorderDate',
          data: 'reorderDate',
          defaultContent: 0,
        },
        {
          title: 'Run Out Date',
          name: 'runOutDate',
          data: 'runOutDate',
          defaultContent: 0,
        },
        {
          title: 'On Hand',
          name: 'onHand',
          data: 'forecast.onHand',
          defaultContent: 0,
        },
        {
          title: 'Usage',
          name: 'usage',
          data: 'forecast.usage',
          defaultContent: 0,
        },
        {
          title: 'Incoming',
          name: 'incoming',
          data: 'forecast.incoming',
          defaultContent: 0,
        });
    }
    setProductListDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const ProductListDataTableButtons = () => {
    let buttons = [];
    if (authUserRole?.roles?.find((d) => d?.code !== 'ROLE_CUSTOMER')) {
      buttons.push({
        text: 'Edit Lead Time',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          setSelectedData({ dtselected });
          handleOpenLeadTime(dtselected);
        },
      });
    }
    if (authUserRole?.roles?.find((d) => d?.code === 'ROLE_SALES_USER') ||
        authUserRole?.roles?.find((d) => d?.code === 'ROLE_CUSTOMER') ||
      (authUserRole?.features?.find((d) => d?.featureCode === 'PO') ||
        customerData?.features?.find((d) => d?.featureCode === 'PO'))
    ) {
      buttons.push({
        text: 'Place Order',
        className: 'btn-dark me-1',
        action: function (e, dt, node, config) {
          let dtselected = dt.rows({ selected: true }).data().toArray();
          setSelectedData({ dtselected });
          handleOpenPlaceOrder(dtselected);
        },
      });
    }
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  const handlePartNumberLink = (event) => {
    let row = $(event.target).data();
    let linkData = {
      partNumber: row.partnumber,
      partName: row.desc,
      id: row.id,
    };

    navigate(
      row.link,
      {
        state: linkData
      }
    )
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: ProductListDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/part/list/cn/${customerData?.customerNumber}?pageSize=1000000&pageIndex=0`,
        type: "GET",
        dataSrc: function (data) {
          setProductListDataTableData(data);
          return data.content;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 50,
      fixedHeader: true,
      scrollY: '450px',
      order: [[1, 'asc']],
      searching: true,
      select: {
        style: 'multi',
      },
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setProductListDataTableOptions(options);
  }, []);

  //Open Orders Data Table settings
  useEffect(() => {
    let columns = [
      {
        title: 'PO Number',
        name: 'ponumber',
        data: 'poNumber',
      },
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'Product Description',
        name: 'productdescription',
        data: 'productDescription',
      },
      {
        title: 'MK SO Number',
        name: 'mksonumber',
        data: 'mkSONumber',
      },
      {
        title: 'Due Date',
        name: 'duedate',
        data: 'dueDate',
        render: function (data) {
          if (data == null) {
            return "No due date";
          }
          return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY');
        },
      },
      {
        title: 'Order Date',
        name: 'orderdate',
        data: 'orderDate',
        render: function (data) {
          return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY');
        }
      },
      {
        title: 'Ship Date',
        name: 'shipdate',
        data: 'shipDate',
        render: function (data) {
          return (data == null) ? '' : moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
        }
      },
      {
        title: 'Quantity',
        name: 'quantity',
        data: 'actualQuantity',
        render: function (data, type, row, meta) {
          return data + ' ' + row.uom;
        },
      },
      {
        title: 'Status',
        name: 'status',
        data: 'status',
      },
    ];

    setOpenOrdersDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const OpenOrdersDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: OpenOrdersDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/customer/${customerData?.customerNumber}/OpenOrders`,
        type: "GET",
        dataSrc: function (data) {
          setOpenOrdersDataTableData(data);
          return data;
        },
        // dataSrc: '',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 10,
      fixedHeader: true,
      scrollY: '450px',
      order: [[1, 'asc']],
      searching: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setOpenOrdersDataTableOptions(options);
  }, []);

  //Incoming MK Inventory Data Table settings
  useEffect(() => {
    let columns = [
      {
        title: 'PO Number',
        name: 'ponumber',
        data: 'purchaseOrderNumber',
      },
      {
        title: 'Part Number',
        name: 'partnumber',
        data: 'partNumber',
      },
      {
        title: 'Part Description',
        name: 'partdescription',
        data: 'partDescription',
        render: function (data, type, row) {
          let linkUri = `/customer-portal/${customerID ?? customerData?.id}/product/${row.partNumber}?cp=true`;
          return `<a href="#" class="part-link" data-link="${linkUri}" data-partnumber="${row.partNumber}" data-desc="${row.description}" data-id="${row.id}">${data}</a>`;
        },
      },
      {
        title: 'Purchase Line Number',
        name: 'linenumber',
        data: 'purchaseLineNumber',
      },
      {
        title: 'Due Date',
        name: 'duedate',
        data: 'dueDate',
        render: function (data) {
          return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY');
        }
      },
      {
        title: 'Quantity',
        name: 'quantity',
        data: 'quantity',
        render: function (data, type, row, meta) {
          return data + ' ' + row.uom;
        },
      },
    ];

    setIncomingInventoryDataTableColumns(columns);
  }, []);

  //Set up Buttons based on privileges
  const IncomingInventoryDataTableButtons = () => {
    let buttons = [];
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: IncomingInventoryDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/mkpart/list/${customerData?.customerNumber}`,
        type: "POST",
        dataSrc: function (data) {
          setIncomingInventoryDataTableData(data.content);
          return data.content;
        },
        // dataSrc: 'content',
        contentType: 'application/json',
        data: function (d) {
          return JSON.stringify({
            pageIndex: 0,
            pageSize: 99999,
            quantity: '',
            search: '',
            sortField: '',
          });
        },
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 10,
      fixedHeader: true,
      scrollY: '450px',
      order: [[1, 'asc']],
      searching: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setIncomingInventoryDataTableOptions(options);
  }, []);

  return (
    <div className="custom-pg-content-sec">
      <Box
        className="custom-pg-titlesticky-sec"
        component="form"
        sx={{
          '& > :not(style)': { ml: 0 },
          display: { md: 'flex', xs: 'box' },
          flexDirection: 'row'
        }}
        noValidate
        autoComplete="off"
      >
        <Box flexGrow={1} className="custom-heading-container-sec" sx={{}}>
          {customerData?.logo && (
            <img
              width={192}
              height={52}
              src={`data:image/jpeg;base64,${logoImage}`}
            ></img>
          )}
        </Box>
        <HeaderBox>{customerData?.customerName}</HeaderBox>
      </Box>
      {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
      <div className="custom-pg-table-content-sec">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <TabItem
            className={`TEST` && tab === 'product list' && 'tabActive'}
            id="TEST"
            onClick={() => setTab('product list')}
          >
            Product List
          </TabItem>
          <TabItem
            className={`TEST2` && tab === 'open orders' && 'tabActive'}
            id="TEST2"
            onClick={() => setTab('open orders')}
          >
            Open Orders
          </TabItem>
          <TabItem
            className={`TEST5` && tab === 'inventory' && 'tabActive'}
            id="TEST5"
            onClick={() => setTab('inventory')}
          >
            Incoming M/K Inventory
          </TabItem>
        </Box>
      </div>
      {/* MODAL AND POPOVERS */}
      <CustomModal open={openModal} onClose={handleModalClose}>
        <Box sx={style}>
          <ModalHeader>Edit Part Numbers</ModalHeader>

          {editLeadsModal()}

          <form onSubmit={handleSubmit}>
            <Box>
              <PartNameText sx={{ margin: '42px 0 16px 0' }}>
                Enter Details
              </PartNameText>
              <Box>
                <LabelBox>Lead Times</LabelBox>
                <Box
                  sx={{
                    '& > :not(style)': {},
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <CustomInput
                    onChange={(e) => handleLeadTimeChange(e)}
                    name="leadTimes"
                    autoComplete="none"
                    type="text"
                  />
                  <InputBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    Wks
                  </InputBox>
                </Box>
              </Box>
              <div style={{ marginTop: '20px' }}>
                <Box
                  sx={{
                    '& > :not(style)': { mr: 2, mt: 3 },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'right'
                  }}
                >
                  <Grid item xs={6} md={2}>
                    <CancelButton onClick={handleModalClose}>
                      Cancel
                    </CancelButton>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <CancelButton type="submit">Update</CancelButton>
                  </Grid>
                </Box>
              </div>
            </Box>
          </form>
        </Box>
      </CustomModal>
      <CustomModal
        open={openPlaceOrderModal}
        onClose={handlePlaceOrderModalClose}
      >
        <Box sx={placeOrderStyle}>
          <PlaceOrderForm
            handleModalClose={handlePlaceOrderModalClose}
            handleDeselect={handleDeselect}
            selectedData={placeOrderData}
            sd={selectedData}
            shippingLocations={shippingLocations}
            setSelectedData={setSelectedData}
            deleteRecord={deleteRecord}
            sx={{ visibility: 'Hidden' }}
          />
        </Box>
      </CustomModal>
      <>
        <div style={{ display: tab === 'product list' ? 'contents' : 'none' }}>
          {productListDataTableColumns && productListDataTableOptions && (
            <TableContainer style={gridStyle}>
              <DataTable
                id={'ProductListTable'}
                columns={productListDataTableColumns}
                options={productListDataTableOptions}
                onRowClick={handlePartNumberLink}
              />
              <Box sx={{}}>
                <ModalButton onClick={() => { $('#dataTable').DataTable().ajax.reload(); }}>
                  Refresh
                </ModalButton>
              </Box>
            </TableContainer>
          )}
        </div>
        <div style={{ display: tab === 'inventory' ? 'contents' : 'none' }}>
          {incomingInventoryDataTableColumns && incomingInventoryDataTableOptions && (
            <TableContainer style={gridStyle}>
              <DataTable
                id={'IncomingInventoryTable'}
                columns={incomingInventoryDataTableColumns}
                options={incomingInventoryDataTableOptions}
                onRowClick={handlePartNumberLink}
              />
            </TableContainer>
          )}
        </div>
        <div style={{ display: tab === 'open orders' ? 'contents' : 'none' }}>
          {openOrdersDataTableColumns && openOrdersDataTableOptions && (
            <TableContainer style={gridStyle}>
              <DataTable
                id={'OpenOrdersTable'}
                columns={openOrdersDataTableColumns}
                options={openOrdersDataTableOptions}
              />
            </TableContainer>
          )}
        </div>
      </>
    </div>
  );
};
export default CustomerPortalTable;
