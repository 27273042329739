import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import Typography from '@mui/material/Typography';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  Box,
  styled,
} from '@mui/material';
import './DataGrid.css';

import { useDispatch, useSelector } from 'react-redux';
import { dashboardReportData } from 'src/redux/actions/DashboardActions';
import { updateStatusByEmail, } from 'src/redux/actions/sysAdminActions';
import moment from 'moment';
import CustomModal from '@mui/material/Modal';
import usePagination from 'src/services/pagination/pagiantion';
import { toast } from 'react-toastify';

import DataTable from 'src/components/DataTables/DataTable';
import SERVER_BASE_URL from 'src/config/config';
import $ from 'jquery';
import ReactDOMServer from 'react-dom/server';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddExtUserForm from "src/content/SystemAdmin/ManageUsers/SingleCustomer/CustomerTabs/ExternalUsers/AddExternalUserForm/AddUserForm"

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { md: '88%', sm: '100%' },
  bgcolor: 'background.paper',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
  pr: 4,
  pl: 4,
  pb: 4,
  bordeRadius: '5px',
  maxHeight: '70%',
  overflowY: 'scroll'
};

const TableContainer = styled(Box)(
  () => `
  padding: 15px 24px;
  background-color: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  `
);

const ExternalUserTable = () => {
  const dispatch = useDispatch();
  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [reporttableData, setReportTableData] = useState();
  const [date, setDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);

  const [editExternalUserOpen, setEditExternalUserOpen] = useState(false);
  const [addExternalUserOpen, setAddExternalUserOpen] = useState(false);
  const [reportExternalUserOpen, setReportExternalUserOpen] = useState(false);

  const [externalUserListDataTableColumns, setExternalUserListDataTableColumns] = useState(false);
  const [externalUserListDataTableOptions, setExternalUserListDataTableOptions] = useState(false);
  const [externalUserListDataTableData, setExternalUserListDataTableData] = useState(false);

  const customerData = useSelector((state) => state?.customers?.customerData);
  const [editExternalUserData, setEditExternalUserData] = useState(false);

  const pencilIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faPencil} />);
  const reportIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faClipboard} />);
  const toggleOnIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faToggleOn} size="2xl" style={{ color: "#17c200" }} />);
  const toggleOffIcon = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faToggleOff} size="2xl" style={{ color: "#000000" }} />);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', sm: '100%' },
    bgcolor: 'background.paper',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.12)',
    p: 4,
    bordeRadius: '5px'
  };

  useEffect(() => {
    let columns = [];
    columns.push(
      {
        title: 'Email',
        name: 'email',
        data: 'email',
        render: function (data, type, row, meta) {
          return `<span style="color: rgb(32, 162, 243);">${row.email}</span>`;
        }
      },
      {
        title: 'First Name',
        name: 'firstname',
        data: 'firstName',
      },
      {
        title: 'Last Name',
        name: 'lastname',
        data: 'lastName',
      },
      {
        title: 'Job Title',
        name: 'jobtitle',
        data: 'jobTitle',
      },
      {
        title: 'Type',
        name: 'type',
        data: 'jobType',
        render: function (data) {
          if (data) {
            return data.description;
          }
          return "";
        }
      },
      {
        title: 'Last Login',
        name: 'lastlogin',
        data: 'lastLogin',
        render: function (data) {
          if (data != null) {
            return moment(data, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').format('MM/DD/YYYY');
          }
          return 'No Login Date';
        }
      },
      {
        title: 'Associated Customers',
        name: 'associatedcustomers',
        data: 'associatedCustomers',
        render: function (data, type, row, meta) {
          let customerString = "";
          for (let i = 0; i < row.associatedCustomers.length; i++) {
            customerString += (i + 1 == row.associatedCustomers.length) ? row.associatedCustomers[i] : row.associatedCustomers[i] + ', ';
          }
          return customerString;
        }
      },
      {
        title: 'Status',
        name: 'status',
        data: 'status',
        render: function (data, type, row) {
          let pillColorClass;
          if (data.description == 'Active') {
            pillColorClass = 'bg-success';
          }
          else if (data.description == 'Request Sent') {
            pillColorClass = 'bg-primary';
          }
          else {
            pillColorClass = 'bg-danger'
          }
          return `<span class="badge rounded-pill px-3 fw-normal ${pillColorClass}">${data.description}</span>`;
        }
      },
      {
        title: 'View Report',
        name: 'viewreport',
        data: 'customerNumber',
        render: function (data, type, row) {
          return `<span role="button" class="ms-3 fs-6" data-button="report" data-email="${row.email}" data-customerNumber="${row.customerNumber}">${reportIcon}</span>`;
        }
      },
      {
        title: 'Action',
        name: 'action',
        data: 'customerNumber',
        render: function (data, type, row) {
          let jobTypeDesc = (row.jobType) ? row.jobType.description : "";
          let jobTypeCode = (row.jobType) ? row.jobType.code : "";
          return `<span role="button" class="ms-3 fs-6" data-button="edit" data-associatedCustomers="${encodeURIComponent(JSON.stringify(row.associatedCustomers))}" data-customerNumber="${encodeURIComponent(JSON.stringify(row.customerNumber))}"
          data-email="${row.email}" data-firstName="${row.firstName}"  data-lastName="${row.lastName}"  data-jobType="${jobTypeDesc}" data-jobCode="${jobTypeCode}" 
          data-jobTitle="${row.jobTitle}" data-features="${encodeURIComponent(JSON.stringify(row.features))}">${pencilIcon}</span>`;
        }
      },
      {
        title: 'Status',
        name: 'status',
        data: 'status',
        render: function (data, type, row) {
          if (data.code === 'A') {
            return `<span role="button" class="ms-3 fs-6" data-button="switch" data-email="${row.email}"  data-customerNumber="${encodeURIComponent(JSON.stringify(row.customerNumber))}"
            data-associatedCustomers="${encodeURIComponent(JSON.stringify(row.associatedCustomers))}" data-code="${data.code}">${toggleOnIcon}</span>`;
          }
          return `<span role="button" class="ms-3 fs-6" data-button="switch" data-email="${row.email}"  data-customerNumber="${encodeURIComponent(JSON.stringify(row.customerNumber))}" 
          data-associatedCustomers="${encodeURIComponent(JSON.stringify(row.associatedCustomers))}" data-code="${data.code}">${toggleOffIcon}</span>`;

        }
      }
    );
    setExternalUserListDataTableColumns(columns);
  }, []);

  const externalUserListDataTableButtons = () => {
    let buttons = [];
    buttons.push({
      text: 'Add User',
      className: 'btn-dark me-1',
      action: function (e, dt, node, config) {
        handleAddOpen();
      }
    })
    buttons.push(
      { extend: 'copy', className: 'btn-outline-secondary' },
      { extend: 'csv', className: 'btn-outline-secondary' },
      { extend: 'print', className: 'btn-outline-secondary' }
    );
    return buttons;
  };

  const handleRowClick = (event) => {
    let row = $(event.currentTarget).data();
    if (row.button == 'edit') {
      handleEditOpen(event);
    }
    if (row.button == 'report') {
      handleReportOpen(event);
    }
    if (row.button == 'switch') {
      handleSwitch(event);
    }
  }

  const handleAddOpen = () => {
    setAddExternalUserOpen(true);
  };

  const handleEditOpen = (event) => {
    let target = event.target;
    if ($(target).closest('svg').data('icon') === "pencil") {
      target = $(event.target).closest('svg').parent();
    }
    let data = $(target).data();

    const customerNumbersOption = JSON.parse(decodeURIComponent(data.associatedcustomers)).map((d) => {
      return d;
    });

    const featuresOption = JSON.parse(decodeURIComponent(data.features)).map((d) => {
      return {
        label: d?.featureDescription,
        code: d?.featureCode,
        value: d?.featureCode
      };
    });

    setEditExternalUserData({
      email: data.email,
      firstName: data.firstname,
      lastName: data.lastname,
      jobType: data.jobcode,
      type: data.jobtype,
      jobTitle: data.jobtitle,
      customerNumber: customerNumbersOption,
      associatedCustomers: customerNumbersOption,
      features: featuresOption,
    });
    setEditExternalUserOpen(true);
  }

  const handleReportOpen = (event) => {
    let target = event.target;
    if ($(target).closest('svg').data('icon') === "clipboard") {
      target = $(event.target).closest('svg').parent();
    }
    let data = $(target).data();
    setReportExternalUserOpen(true);
    dispatch(dashboardReportData(data.email, date, authToken));

    //implement data handling here for future improvements?  Left in Compunnel handling for reference
  }

  const handleSwitch = async (event) => {
    event?.preventDefault();
    let target = event.target;
    if ($(target).closest('svg').data('icon') === "toggle-on" || $(target).closest('svg').data('icon') === "toggle-off") {
      target = $(event.target).closest('svg').parent();
    }
    let data = $(target).data();

    const customerNumbersOption = JSON.parse(decodeURIComponent(data.associatedcustomers)).map((d) => {
      return d;
    });

    const code = (data.code === 'A') ? 'D' : 'A';
    dispatch(updateStatusByEmail(
      customerNumbersOption,
      data.email,
      code,
      authToken,
      'getAllExternalUsers'
    )
    );
    toast.success('Changing status...')
  }

  const handleAddClose = () => {
    setAddExternalUserOpen(false);
  };

  const handleEditClose = () => {
    setEditExternalUserOpen(false);
  };

  const handleReportClose = () => {
    setReportExternalUserOpen(false);
  };

  useEffect(() => {
    let options = {
      dom: "<'row align-middle mb-1'<'col'f<'tableControls'>><'col text-right'B>>" +
        "<'row'<'col-12'tr>>" +
        "<'row'<'col text-left'i><'col'p>><'#bottomlink'>",
      buttons: externalUserListDataTableButtons(),
      ajax: {
        url: `${SERVER_BASE_URL}/customer/${customerData?.customerNumber}/EU`, //double check url path when given row of data
        type: "GET",
        dataSrc: function (data) {
          setExternalUserListDataTableData(data);
          return data;
        },
        // dataSrc: 'content',
        dataType: 'JSON',
        timeout: 0,
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
      },
      info: true,
      processing: true,
      retrieve: true,
      pageResize: true,
      scrollCollapse: true,
      lengthChange: false,
      pageLength: 5,
      fixedHeader: true,
      // scrollY: '450px',
      order: [[7, 'asc']],
      searching: true,
      select: false,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search',
      }
    };
    setExternalUserListDataTableOptions(options);
  }, [customerData?.customerNumber]);

  const getDashboardReportData = useSelector(
    (state) => state?.dashboard?.getReportData
  );

  const authToken = useSelector((state) => state?.auth?.token?.token);

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', margin: '0px 0 12px 0' }),
    []
  );

  const [filteredData, setFilteredData] = React.useState({
    status: ['Active'],
    jobType: [],
    date: {}
  });

  let width = 100;

  const onGridSizeChanged = (params) => {
    let columnCount = params?.columnApi?.columnModel?.gridColumns?.length;
    width = params?.clientWidth / columnCount;
    params?.api?.sizeColumnsToFit();
  };

  const reportbluePrint = (data) => {
    return (
      data &&
      data?.map((d) => {
        return {
          loggedDate: moment(d?.loggedDate).format('MM-DD-YYYY'),
          totalUsageSeconds: d?.totalUsageSeconds
            ? (d?.totalUsageSeconds / 3600).toFixed(2) + ' h'
            : 0,
          baseFeature: d?.featureUsageTime['Base Feature']
            ? Math.floor(d?.featureUsageTime['Base Feature'] / 60) + ' m'
            : 0,
          forecastFeature: d?.featureUsageTime['Forecast']
            ? Math.floor(d?.featureUsageTime['Forecast'] / 60) + ' m'
            : 0,
          bulkOrderFeature: d?.featureUsageTime['Bulk Order']
            ? Math.floor(d?.featureUsageTime['Bulk Order'] / 60) + ' m'
            : 0,
          blanketPoFeature: d?.featureUsageTime['Blanket Po']
            ? Math.floor(d?.featureUsageTime['Blanket Po'] / 60) + ' m'
            : 0,
          barCodeScannerFeature: d?.featureUsageTime['Barcode Scanner']
            ? Math.floor(d?.featureUsageTime['Barcode Scanner'] / 60) + ' m'
            : 0
        };
      })
    );
  };

  useEffect(() => {
    if (getDashboardReportData) {
      const tableBluePrint = reportbluePrint(getDashboardReportData);

      const data = usePagination(
        tableBluePrint,
        10,
        currentPage,
        setCurrentPage
      );
      setReportTableData(data?.currentData());
    }
  }, [getDashboardReportData, filteredData]);

  return (
    <Box>
      <TableContainer style={gridStyle}>
        {externalUserListDataTableColumns && externalUserListDataTableOptions && (
          <DataTable
            id={'externalUserListTable'}
            columns={externalUserListDataTableColumns}
            options={externalUserListDataTableOptions}
            onRowClick={handleRowClick}
          />
        )}
      </TableContainer>
      <>
        <CustomModal id="addModal" className="edit_external_modal" keepMounted open={addExternalUserOpen} onClose={handleAddClose}>
          <Box sx={style}>
            <div>
              <Typography id="keep-mounted-modal-title" variant="h3" component="h2">Add External User</Typography>
              <span> All fields with <span style={{ color: 'red' }}> *</span> are mandatory</span>
            </div>
            <div style={{ marginTop: '30px' }}>
              <AddExtUserForm close={handleAddClose} />
            </div>
          </Box>
        </CustomModal>
      </>

      <CustomModal id="editModal" className="edit_external_modal" open={editExternalUserOpen} onClose={handleEditClose}>
        <Box sx={style}>
          <div>
            <Typography id="keep-mounted-modal-title" variant="h3" component="h2">Edit External User</Typography>
            <span>All fields with <span style={{ color: 'red' }}> *</span> are mandatory</span>
          </div>
          <div style={{ marginTop: '30px' }}>
            <AddExtUserForm extUserData={editExternalUserData} close={handleEditClose} edit={true} />
          </div>
        </Box>
      </CustomModal>

      <CustomModal open={reportExternalUserOpen} onClose={handleReportClose}>
        <Box sx={modalStyle}>
          <div style={{ fontWeight: 700, fontSize: '16px', margin: '25px 0', color: '#15364A' }}>Report</div>
          <Box className="ag-theme-alpine" style={{ maxWidth: '100%', width: '100%' }}>
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API
              domLayout={'autoHeight'}
              onGridSizeChanged={onGridSizeChanged}
              // defaultColDef={defaultColDef} // Default Column Properties
              rowData={reporttableData}
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            >
              <AgGridColumn headerName="Access Log Report">
                <AgGridColumn headerName="" field="loggedDate"></AgGridColumn>
              </AgGridColumn>
              <AgGridColumn headerName="No of Features">
                <AgGridColumn
                  field="bulkOrderFeature"
                  headerName="Bulkupload"
                ></AgGridColumn>
                <AgGridColumn
                  field="baseFeature"
                  headerName="Base Feature"
                ></AgGridColumn>
                <AgGridColumn
                  field="barCodeScannerFeature"
                  headerName="Barcode Scanner"
                ></AgGridColumn>
                <AgGridColumn
                  field="forecastFeature"
                  headerName="Forecast"
                ></AgGridColumn>
                <AgGridColumn
                  field="blanketPoFeature"
                  headerName="Blanket PO"
                ></AgGridColumn>
              </AgGridColumn>
              <AgGridColumn headerName="Total No. of Hours Spent">
                <AgGridColumn
                  field="totalUsageSeconds"
                  headerName=""
                ></AgGridColumn>
              </AgGridColumn>
            </AgGridReact>{' '}
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default ExternalUserTable;
